import 'flatpickr/dist/themes/material_green.css';

import React, { useEffect, useState } from 'react';

import Flatpickr from 'react-flatpickr';

import ButtonNavigate from '@/common/buttons/ArrowButton';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ThreeDot from '@/common/loading/ThreeDot';
import ModalBase from '@/common/modals/ModalBase';
import { breakpoints } from '@/constants';
import useWindowSize from '@/hooks/useWindowSize';
import { useMemberShipContext } from '@/context/MembershipContext';
import { calculateDateFromSpecificDate } from '@/utils/CalculateDateFromSpecificDate';
import { getUserLocalDate } from '@/helpers/date.helper';
import moment from 'moment';

const CancelMembership = ({
  openModal,
  setModal,
  endDateCancel,
  setDateEnd,
  modalLoading,
  setCancelItem,
  handleCancel,
  confirm,
  cancelStep,
  setCancelStep,
  userInfo,
  reasonCancel,
  setReasonCancel,
  setAccept,
  accept,
  setTab,
  setRevokeModal,
}) => {
  const { userSelectedMembership, setUserSelectedMembership, membershipInfo } =
    useMemberShipContext();
  const endContractDate = calculateDateFromSpecificDate({
    type: userSelectedMembership?.contractLength?.type,
    amount: userSelectedMembership?.contractLength?.amount,
    date: userSelectedMembership?.startDateTime,
  });
  const isInCoolingOffPeriod =
    moment().diff(userSelectedMembership?.startDateTime, 'days') < 8;
  const [isContractEnd, setIsContractEnd] = useState(false);
  const { width } = useWindowSize();
  const [openModalWarning, setOpenModalWarning] = useState(false);

  useEffect(() => {
    setIsContractEnd(moment().isAfter(endContractDate));
  }, [endContractDate]);

  const otherReason = reasonCancel.find((i) => i.name === 'Other');
  const isOtherOptionSelected = otherReason?.select ?? false;
  const isOtherReasonGiven = isOtherOptionSelected && !!otherReason?.details;
  const isReasonValid = isOtherOptionSelected
    ? isOtherReasonGiven
    : reasonCancel.some((i) => i.select);
  const handleCancelUi = () => (
    <>
      {cancelStep == 0 && (
        <div className="sm:mt-[8px] mt-[30px] lg:w-full pb-[32px]  border-[1.5px] border-[rgb(255,255,255,0.4)] sm:border-none  rounded sm:bg-transparent bg-[#13181b]">
          <div className="sm:h-auto sm:px-6 h-[57px] sm:border-none border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase">
            <p className="sm:hidden sm:border-none font-bicyclette border-white   tracking-[1.4px] py-[19.5px]  h-[57px] px-[27px]  text-white font-bold  flex justify-between items-center uppercase">
              {userSelectedMembership?.expiryDateTime
                ? 'Revoke cancelation'
                : 'REQUEST CANCELLATION'}
            </p>
            <p className="sm:block text-primary hidden font-bold text-[20px] leading-[90%] font-bicyclette">
              BEFORE YOU GO{' '}
            </p>
          </div>
          <>
            <div className="grid grid-cols-2 sm:grid-cols-1 sm:gap-[28px] gap-[150px] px-6 pt-6 sm:pt-3">
              {userSelectedMembership.recurring ? (
                <p className="text-white gotham text-sm leading-[21.36px]">
                  Are you sure you want to{' '}
                  {userSelectedMembership?.expiryDateTime
                    ? 'cancel the cancelation'
                    : 'cancel the membership'}
                  ? We offer membership suspend for up to 90-days
                  free-of-charge.
                </p>
              ) : (
                <p className="text-white gotham text-sm leading-[21.36px]">
                  Please contact the local plus fitness
                </p>
              )}
              {userSelectedMembership?.expiryDateTime ? (
                <div className="flex flex-row lg:flex-col justify-end items-end gap-6">
                  <div
                    className={`w-[180px] flex items-center  justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black text-base`}
                    onClick={() => {
                      setRevokeModal(true);
                    }}
                  >
                    request cancelation
                  </div>
                  {/* <div
                      onClick={() => setCancelStep(1)}
                      className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                  >
                      yes, i'm sure
                  </div> */}
                </div>
              ) : (
                <>
                  {' '}
                  {userSelectedMembership.recurring === true && (
                    <div className="flex flex-row lg:flex-col justify-end sm:items-end items-center sm:gap-5 gap-6">
                      {!userInfo?.isBlocked && (
                        <div
                          className="sm:w-full text-[11px] w-[155px] font-medium sm:font-bold flex items-center justify-center cursor-pointer gotham uppercase rounded border  transition-all border-gray text-white sm:h-[52px] h-[36px] hover:bg-primary hover:border-primary hover:text-white sm:text-base"
                          onClick={() => setTab(2)}
                        >
                          Suspend instead
                        </div>
                      )}
                      <div
                        onClick={() => setCancelStep(1)}
                        className="sm:w-full w-[135px] font-medium sm:font-bold flex items-center justify-center cursor-pointer gotham text-[11px] uppercase rounded border  transition-all border-gray text-white sm:h-[52px] h-[36px] hover:bg-primary hover:border-primary hover:text-white sm:text-base"
                      >
                        yes, i'm sure
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        </div>
      )}
      {cancelStep == 1 && (
        <div className="sm:mt-[8px] mt-[30px] lg:w-full pb-[32px] sm:pb-11 border-[1.5px] border-[rgb(255,255,255,0.4)] sm:border-none rounded sm:bg-transparent bg-[#13181b]">
          <div className="sm:h-auto sm:px-6 sm:text-primary h-[57px] px-[27px] sm:border-none border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase sm:mb-2">
            <p className="sm:font-bold sm:block flex justify-between w-full items-center sm:text-[20px] sm:leading-[90%] font-bicyclette">
              reason for cancellation
              <br />
              <span className="sm:pt-[10px] text-primary sm:text-textSecondary text-sm normal-case leading-6 inline-block sm:font-normal">
                Tick all that apply*
              </span>
            </p>
          </div>
          {reasonCancel.map((i, ind) => (
            <div
              className={`${
                ind == reasonCancel.length - 1 ? 'flex-wrap' : ''
              } flex sm:mx-6 sm:gap-[10px] sm:flex-row-reverse mx-[30px] text-white items-center sm:px-0 px-[7px] sm:justify-end justify-between ${
                ind + 1 !== reasonCancel?.length
                  ? ' border-b border-white sm:border-textSecondary '
                  : ' '
              } ${
                i.name.toLocaleLowerCase() === 'standard uspension' &&
                reasonCancel.filter(
                  (i) => i.name.toLocaleLowerCase() === 'standard suspension'
                )[0]?.select &&
                width &&
                width <= breakpoints.sm
                  ? '!border-b-transparent'
                  : ''
              } ${ind == 0 && 'pt-[14px]'} border-white py-[12px] `}
              key={ind}
            >
              <p className="gotham  text-white tracking-[0.01em] text-sm font-normal sm:font-normal ">
                {i.name}
              </p>
              <div
                className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                  i.select ? 'bg-white' : ' border-white'
                }`}
                onClick={() => {
                  const newData = [...reasonCancel].map((item, index) =>
                    index == ind ? { ...item, select: !item.select } : item
                  );
                  if (i.name === 'Other' && !i.select) {
                    newData[ind].details = '';
                  }
                  setReasonCancel(newData);
                }}
              >
                {i.select && (
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.69231L5.2 9L13 1"
                      stroke={'black'}
                      strokeWidth="1.02"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
              {i.name === 'Other' && i.select ? (
                <>
                  <div
                    className={`w-full gotham text-[11px] ${
                      isOtherOptionSelected && !isOtherReasonGiven
                        ? 'text-red-500'
                        : 'text-gray'
                    }`}
                  >
                    Please provide details below*
                  </div>
                  <textarea
                    className="w-full h-[58px] sm:h-auto bg-transparent text-white border-b border-gray outline-none text-sm pt-[38px] align-bottom text-gray"
                    value={i?.details}
                    onChange={(e) => {
                      const newData = [...reasonCancel].map((item) =>
                        item.name === 'Other'
                          ? { ...item, details: e.target.value.trim() }
                          : item
                      );
                      setReasonCancel(newData);
                    }}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      )}
      {(isReasonValid || (width && width <= breakpoints.sm)) && (
        <div className="sm:mt-0 mt-[30px] lg:w-full pb-[22px] sm:pb-[32px] sm:border-none border-[1.4px] border-[rgb(255,255,255,0.4)] rounded sm:bg-transparent bg-[#13181b] mb-6">
          <div className="sm:hidden sm:h-auto sm:border-transparent h-[57px] px-[27px] border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase">
            <p>BEFORE YOU GO</p>
          </div>
          <div className="mx-6 sm:mt-0 mt-6 sm:gap-6 grid grid-cols-3 sm:grid-cols-1">
            {cancelStep == 1 && (
              <>
                <div
                  className={`sm:col-span-1 col-span-2 flex select-none sm:flex-wrap sm:items-center ${
                    !isReasonValid
                      ? ' sm:pointer-events-none sm:opacity-40'
                      : ' sm:opacity-100 sm:pointer-events-auto'
                  }`}
                >
                  <div
                    className={`text-white shrink-0 w-[30px] rounded-[5px] h-[30px] border flex justify-center items-center ${
                      accept ? 'bg-white' : 'border-white'
                    }`}
                    onClick={() => {
                      setAccept(!accept);
                    }}
                  >
                    {accept && (
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.69231L5.2 9L13 1"
                          stroke={'black'}
                          strokeWidth="1.02"
                          fill="white"
                        />
                      </svg>
                    )}
                  </div>
                  <p className="hidden sm:block text-white text-base ml-3 sm:font-normal">
                    Terms and Conditions*
                  </p>
                  <p className="ml-[16px] gotham text-[14px] inline-block text-white sm:w-full sm:ml-0 sm:mt-3 sm:font-normal sm:leading-[120%]">
                    I accept that it will take 28 days for my direct debits to
                    end and that I may be contacted with an offer that could aid
                    with my reason for cancellation. I understand that by
                    cancelling I will forgo my current membership fees and will
                    have to pay a reactivation fee to sign up again.
                  </p>
                </div>
                <div
                  onClick={() => {
                    if (!accept || !isReasonValid) return;
                    if (
                      userSelectedMembership?.recurring &&
                      userSelectedMembership?.contractLength?.amount &&
                      userSelectedMembership?.contractLength?.type &&
                      !isContractEnd &&
                      !isInCoolingOffPeriod
                    ) {
                      accept && setOpenModalWarning(!openModalWarning);
                    } else {
                      accept && setModal(!openModal);
                    }
                  }}
                  className={`ml-auto sm:m-0 my-auto sm:w-full w-[209px] text-[11px] font-medium sm:h-[52px] flex items-center justify-center sm:font-bold gotham sm:text-base uppercase rounded border transition-all h-[37px] ${
                    accept && isReasonValid
                      ? 'border-gray text-white hover:bg-white hover:text-black cursor-pointer'
                      : 'border-gray text-gray cursor-not-allowed opacity-40'
                  }`}
                >
                  {/* cancel my membership */}
                  REQUEST CANCELLATION
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
  const isUserStateSA = userInfo.homeLocationDetails.state === 'SA';
  return (
    <>
      {width && width < breakpoints.sm
        ? !openModal && handleCancelUi()
        : handleCancelUi()}
      {width && width < breakpoints.sm && openModal ? (
        !confirm ? (
          <div className="mx-6 flex flex-col gap-5 my-3">
            {modalLoading && (
              <div className="fixed top-0 left-0 h-screen w-screen">
                <ThreeDot />
              </div>
            )}
            {isInCoolingOffPeriod ? (
              <>
                <div className="text-[20px] uppercase font-bold text-primary font-bicyclette">
                  You are in a 7 day cooling off period. Would you like to
                  cancel your membership?
                </div>
                <label className="text-white">
                  Select date of cancellation*
                </label>
                <div className="w-full h-[30px] border-b border-gray text-white">
                  {getUserLocalDate().today.format('DD/MM/YYYY')}
                </div>
              </>
            ) : (
              <>
                <div className="text-[20px] uppercase font-bold text-primary font-bicyclette">
                  what date would you like your cancellation to take place?
                </div>
                <label className="self-center mt-2">
                  <div
                    className={`border-b sm:w-[calc(100vw-48px)] w-75 self-start h-[30px]  pb-[2px]  relative ${
                      endDateCancel !== '' ? 'border-b-gray' : 'border-b-gray'
                    } `}
                  >
                    <div className="absolute  bottom-[6px] w-full">
                      <Flatpickr
                        onChange={(dateObj, dateStr) =>
                          setDateEnd(dateStr.split('/').reverse().join('-'))
                        }
                        options={{
                          dateFormat: 'd/m/Y',
                          disableMobile: 'true',
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                          minDate: isUserStateSA
                            ? new Date().fp_incr(14)
                            : new Date().fp_incr(28),
                        }}
                        value={
                          endDateCancel
                            ? new Date(endDateCancel)
                            : isUserStateSA
                            ? new Date().fp_incr(14)
                            : new Date().fp_incr(28)
                        }
                      />
                    </div>
                    <label
                      className={`gotham text-[12px] absolute left-0 ${
                        endDateCancel !== ''
                          ? 'text-white top-[2px] sm:top-[-8px]'
                          : 'text-gray bottom-[14px]'
                      }`}
                    >
                      Select date of cancellation*
                    </label>
                    <div className="absolute  z-10 right-3 bottom-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                        fill="none"
                      >
                        <g clipPath="url(#clip0_1_1209)">
                          <path
                            d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                            fill="white"
                          />
                          <path
                            d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1_1209">
                            <rect width="20" height="19" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </label>
              </>
            )}

            <div className="flex gap-5 justify-center">
              <PrimaryOutlinedButton
                className="uppercase w-[153px] h-[37px] gotham text-[11px] border border-white rounded-md sm:font-bold"
                onClick={async () => {
                  try {
                    setCancelItem(userSelectedMembership.id);
                    await handleCancel(userSelectedMembership.id);
                  } catch (e) {}
                }}
              >
                yes
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                className="uppercase w-[153px] h-[37px] gotham text-[11px] border border-white rounded-md sm:font-bold"
                onClick={() => setModal(false)}
              >
                no
              </PrimaryOutlinedButton>
            </div>
          </div>
        ) : (
          confirm && (
            <div className="mx-6 flex flex-col gap-4 my-4">
              <div className="text-[20px] uppercase font-bold text-primary font-bicyclette">
                this isn’t goodbye,
                <br />
                it’s see you soon
              </div>
              <p className="text-white text-base sm:text-sm sm:font-normal sm:mb-4">
                Thank you for requesting a cancellation, should you have any
                questions please contact you local Plus Fitness.
              </p>
              <div className="flex flex-col gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-full h-[52px] gotham text-[11px] border border-white rounded-md sm:font-bold sm:text-base"
                  onClick={() => {
                    window.location.replace('/dashboard/memberships');
                  }}
                >
                  my membership
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-full h-[52px] gotham text-[11px] border border-white rounded-md sm:font-bold sm:text-base"
                  onClick={() => {
                    window.location.replace('/dashboard');
                  }}
                >
                  go to dashboard
                </PrimaryOutlinedButton>
              </div>
            </div>
          )
        )
      ) : (
        ''
      )}
      {openModal && width && width > breakpoints.sm && (
        <ModalBase noBaseHeader loading={modalLoading}>
          <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
            <div className="flex items-center gap-[15px] ">
              <span className="uppercase gotham tracking-[0.01em]">
                {/* CANCEL MEMBERSHIP */}
                REQUEST CANCELLATION
              </span>
            </div>
            {!confirm && (
              <div
                onClick={() => setModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pr-6 pl-[33px] flex gap-[36px] w-[688px] sm:w-unset my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[40px] font-bold font-bicyclette gravity uppercase text-center">
              {!confirm &&
                (isInCoolingOffPeriod ? (
                  <>
                    You are in a 7 day cooling off period.
                    <br />
                    Would you like to cancel your membership?
                  </>
                ) : (
                  <>
                    what date would you like
                    <br />
                    your cancellation to take place?
                  </>
                ))}
              {confirm && 'This isn’t goodbye, it’s see you soon'}
            </p>
            {!confirm && (
              <>
                <label className="self-center">
                  <div
                    className={`border-b w-72 self-start h-[59px]  pb-[2px]  relative ${
                      endDateCancel !== '' ? 'border-b-gray' : 'border-b-gray'
                    } `}
                  >
                    <div className="absolute  bottom-[6px] w-full">
                      <Flatpickr
                        disabled={isInCoolingOffPeriod}
                        onChange={(dateObj, dateStr) =>
                          setDateEnd(dateStr.split('/').reverse().join('-'))
                        }
                        options={{
                          dateFormat: 'd/m/Y',
                          disableMobile: 'true',
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                          minDate: isUserStateSA
                            ? new Date().fp_incr(14)
                            : new Date().fp_incr(28),
                        }}
                        value={
                          endDateCancel
                            ? new Date(endDateCancel)
                            : isUserStateSA
                            ? new Date().fp_incr(14)
                            : new Date().fp_incr(28)
                        }
                      />
                    </div>
                    <label
                      className={`gotham text-[12px] absolute left-0 ${
                        endDateCancel !== ''
                          ? 'text-white top-[2px] sm:top-[-8px]'
                          : 'text-gray bottom-[14px]'
                      }`}
                    >
                      {isInCoolingOffPeriod
                        ? 'Date of cancellation'
                        : 'Select date of cancellation*'}
                    </label>
                    {isInCoolingOffPeriod ? (
                      <div className="absolute  bottom-[6px] w-full">
                        {getUserLocalDate().today.format('DD/MM/YYYY')}
                      </div>
                    ) : (
                      <div className="absolute  z-10 right-3 bottom-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_1209)">
                            <path
                              d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_1209">
                              <rect width="20" height="19" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    )}
                  </div>
                </label>
              </>
            )}
            {!confirm && (
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={async () => {
                    try {
                      setCancelItem(userSelectedMembership.id);
                      await handleCancel(userSelectedMembership.id);
                    } catch (e) {}
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={() => setModal(false)}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
            )}
            {!confirm && (
              <p className="gotham text-sm leading-[22px] text-white text-center"></p>
            )}
            {confirm && (
              <p className="gotham text-sm leading-[22px] text-white text-center">
                Thank you for requesting a cancellation, should you have any
                questions please contact you local Plus Fitness.
              </p>
            )}
            {confirm && (
              <ButtonNavigate
                text="BACK TO DASHBOARD"
                onclick={() => {
                  setModal(false);
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            )}
          </div>
        </ModalBase>
      )}
      {openModalWarning && (
        <ModalBase noBaseHeader loading={modalLoading}>
          <div className="pr-6 pl-[33px] flex gap-[36px] w-[688px] sm:w-unset my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[30px] font-bold font-bicyclette gravity uppercase text-center">
              You cannot cancel your membership as you are in a contract. Please
              contact your home club.
            </p>

            <div className="flex gap-5 justify-center">
              <PrimaryOutlinedButton
                className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                onClick={async () => {
                  setOpenModalWarning(!openModalWarning);
                }}
              >
                Cancel
              </PrimaryOutlinedButton>
            </div>
          </div>
        </ModalBase>
      )}
    </>
  );
};

export default CancelMembership;
