import { calculateDateFromSpecificDate } from './CalculateDateFromSpecificDate';

export const checkIfMemberInContractPeriod = ({
  type,
  amount,
  date,
}: {
  type: string;
  amount: number;
  date: string;
}) => {
  const contractPeriod = calculateDateFromSpecificDate({
    type,
    amount,
    date,
  });
  const today = new Date();
  const contractDate = new Date(contractPeriod);

  return today <= contractDate;
};
