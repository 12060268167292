import React, { useContext, useEffect, useState } from 'react';
import tick from '../../assets/images/tick.svg';
import { RegisterContext } from '@/context/RegisterContext';
import moment from 'moment';
const CardVoucher = (props) => {
  function createMarkup(data) {
    return { __html: data };
  }
  const yourClub = useContext(RegisterContext).yourClub;

  const voucherType = props.voucherDetail.type;
  const voucherHaveMembership =
    props.voucherDetail.type === 'PERCENT' ||
    props.voucherDetail.type === 'FIXED';
  const isPif = !props.voucherDetail?.membershipDetail?.recurring;

  const [percentPrice, setPercentPrice] = useState<number>(0);
  const currentMaintenance = yourClub?.locationMaintenance?.items?.filter(
    (item) =>
      moment().isBetween(item.startDateTime, item.endDateTime, null, '[]') &&
      !item.isDeleted
  )?.[0];

  useEffect(() => {
    if (
      props.info?.memberDetails?.membershipId ===
        props.voucherDetail.membershipDetail?.id ||
      props.info?.membershipId === props.voucherDetail.membershipDetail?.id
    ) {
      if (isPif) {
        props.setType && props.setType('pif');
      } else {
        props.setType && props.setType('weekly');
      }

      props.setInfo({
        ...props.info,
        membershipId: props.voucherDetail?.membershipDetail?.id,
        membershipLocationId:
          props.voucherDetail?.membershipDetail?.membershipLocations?.items.filter(
            (item) =>
              item.locationId === props?.info?.memberDetails?.homeLocationId
          )[0]?.id,
        voucherCode: props.voucherDetail.voucherCode,
        membershipStartDate:
          yourClub?.openDate && moment(yourClub?.openDate).isAfter(moment())
            ? yourClub?.openDate
            : Object.keys(currentMaintenance || {})?.length > 0
            ? moment(currentMaintenance?.endDateTime).add(1, 'days')
            : new Date(),
      });
      const membershipLocation =
        props.voucherDetail?.membershipDetail?.membershipLocations.items.filter(
          (item) =>
            item.locationId === props?.info?.memberDetails?.homeLocationId
        );
      setPercentPrice(membershipLocation?.[0]?.costPrice);
    }
  }, [props.voucherDetail]);

  const handleChoosePlan = () => {
    if (props.joining) {
      if (isPif) {
        props.setType('pif');
      } else {
        props.setType('weekly');
      }

      props.setInfo({
        ...props.info,
        membershipId: props.voucherDetail?.membershipDetail?.id,
        membershipLocationId:
          props.voucherDetail?.membershipDetail?.membershipLocations?.items.filter(
            (item) =>
              item.locationId === props?.info?.memberDetails?.homeLocationId
          )[0]?.id,
        membershipStartDate:
          yourClub?.openDate && moment(yourClub?.openDate).isAfter(moment())
            ? yourClub?.openDate
            : Object.keys(currentMaintenance || {})?.length > 0
            ? moment(currentMaintenance?.endDateTime).add(1, 'days')
            : new Date(),
        voucherCode: props.voucherDetail.voucherCode,
      });
    }
  };

  return (
    <div
      className={` h-auto align-center  text-center items-center border border-gray rounded flex flex-col ${
        props?.changeMembership
          ? 'bg-[#414141] w-full'
          : 'bg-richBlack w-[450px]'
      }`}
    >
      <div className="h-[30px] w-[250px] mb-[10px] bg-primary rounded-b flex justify-center items-center">
        <p className="text-white text-sm ">PROMO CODE APPLIED</p>
      </div>
      {voucherHaveMembership ? (
        <span className="uppercase heading2 text-white text-center leading-[90%] mb-[10px] ">
          {props.voucherDetail?.membershipDetail?.membershipName}
        </span>
      ) : (
        <span className="uppercase heading2 text-white text-center leading-[90%] mb-[10px] ">
          PF
          <span className="heading2 ml-[10px] text-[rgba(37,51,213,1)]">
            {voucherType === 'VISIT_PASS' ? 'VISIT' : 'COMPLIMENTARY'}
          </span>
        </span>
      )}

      {voucherHaveMembership ? (
        <div>
          <span className="bebas flex sm:text-center text-[45px] text-white mb-[10px] leading-[80%] justify-center">
            {/* <div className=" mt-auto relative justify-center mr-[10px]">
              <div
                className="bebas text-gray text-[25px]  after:absolute after:bottom-[11px] after:left-0
           after:content-[''] after:w-[100%] after:h-[2px] after:bg-primary before:absolute 
           before:bottom-[11px]  before:content-[''] before:w-[100%] before:h-[2px] before:bg-primary 
           transform after:rotate-[20deg] before:-rotate-[20deg] "
              >
                {`$${(
                  props.voucherDetail?.membershipDetail?.costPrice /
                  props.voucherDetail?.membershipDetail?.paymentFrequency.amount
                ).toFixed(2)}`}
              </div>
            </div> */}
            {props.voucherDetail?.type === 'FIXED' && (
              <p className="bebas flex sm:text-center text-[45px] text-white  leading-[80%] ">
                {`$${
                  isPif
                    ? (props.voucherDetail?.cost).toFixed(2)
                    : (
                        props.voucherDetail?.cost /
                        props.voucherDetail?.membershipDetail?.paymentFrequency
                          .amount
                      ).toFixed(2)
                } `}
              </p>
            )}
            {props.voucherDetail?.type === 'PERCENT' && (
              <p className="bebas flex sm:text-center text-[45px] text-white  leading-[80%]">
                {`$${(
                  (percentPrice -
                    percentPrice * (props.voucherDetail?.cost * 0.01)) /
                  props.voucherDetail?.membershipDetail?.paymentFrequency.amount
                ).toFixed(2)} `}
              </p>
            )}
            {!isPif && (
              <p className="mt-auto">
                /{props.voucherDetail?.membershipDetail?.paymentFrequency.type}
              </p>
            )}
          </span>

          {!props.voucherDetail?.membershipDetail?.recurring ? (
            <p className="text-[rgba(255,255,255,0.6)] text-center text-xs font-normal">
              SINGLE PAYMENT
            </p>
          ) : (
            <div>
              {voucherType === 'PERCENT' ? (
                <p className="text-[rgba(255,255,255,0.6)] text-center text-xs font-normal">
                  {`$${(
                    percentPrice -
                    percentPrice * (props.voucherDetail?.cost * 0.01)
                  ).toFixed(2)}  fortnightly Direct Debit`}
                </p>
              ) : (
                <p className="text-[rgba(255,255,255,0.6)] text-center text-xs font-normal">
                  {`$${props.voucherDetail?.cost.toFixed(
                    2
                  )}  fortnightly Direct Debit`}
                </p>
              )}
            </div>
          )}
        </div>
      ) : (
        <span>
          {' '}
          {props.voucherDetail?.type !== 'COMPLIMENTARY' ? (
            <p className="bebas flex sm:text-center text-[45px] text-white  leading-[80%]">
              ${props.voucherDetail?.cost}
            </p>
          ) : (
            <p className="bebas flex sm:text-center text-[45px] text-white  leading-[80%]">
              $
              {props.voucherDetail?.passFee +
                props.voucherDetail?.activationFee +
                props.voucherDetail?.joiningFee}
            </p>
          )}
        </span>
      )}
      {voucherType === 'FIXED' || voucherType === 'PERCENT' ? (
        <div className="text-white border-t my-[20px] border-t-borderLine w-[150px] text-center ">
          <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
            {[
              ...(props.voucherDetail?.membershipDetail?.description.includes(
                'All Plus Fitness 24/7'
              )
                ? ['"""All Plus Fitness 24/7']
                : ['']),
              ...(props.voucherDetail?.membershipDetail?.description.includes(
                'Eligibility'
              )
                ? ['Eligibility: N/A']
                : ['']),
              `$${props.voucherDetail?.joiningFee} Joining  fee`,
              `$${props.voucherDetail?.activationFee} Activation fee`,
              `$${props.voucherDetail?.passFee} Pass fee`,
            ].map((i) => {
              if (i) {
                return (
                  <li
                    className={`flex items-center gap-[14px] sm:gap-[10px]'
                }`}
                  >
                    <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                      <img className="w-auto h-auto" src={tick} alt="icon" />
                    </span>
                    <span
                      className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                      dangerouslySetInnerHTML={createMarkup(
                        i?.replace('\n', '<br/>')
                      )}
                    ></span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      ) : (
        <div className="text-white border-t my-[20px] border-t-borderLine w-[150px] text-center ">
          <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
            {[
              ...props.voucherDetail?.note
                .split(',')
                .concat([
                  ...(props.voucherDetail.type === 'VISIT_PASS'
                    ? [`For ${props.voucherDetail.visit} visits`]
                    : [`For ${props.voucherDetail.duration} days`]),
                  `$${props.voucherDetail?.joiningFee} Joining fee`,
                  `$${props.voucherDetail?.activationFee} Activation fee`,
                  `$${props.voucherDetail?.passFee} Pass fee`,
                ]),
            ].map((i) => {
              if (i) {
                return (
                  <li
                    className={`flex items-center gap-[14px] sm:gap-[10px]'
                }`}
                  >
                    <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                      <img className="w-auto h-auto" src={tick} alt="icon" />
                    </span>
                    <span
                      className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                      dangerouslySetInnerHTML={createMarkup(
                        i?.replace('\n', '<br/>')
                      )}
                    ></span>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      )}
      {props.joining && (
        <div>
          <div
            className={`h-[37px] cursor-pointer mb-[10px] transition-all w-[130px] hover:bg-primary hover:!border-primary border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white ${
              props.info.membershipId ===
              props.voucherDetail.membershipDetail?.id
                ? 'bg-primary text-white !border-primary'
                : ''
            }`}
            onClick={handleChoosePlan}
          >
            {props.info.membershipId ===
            props.voucherDetail.membershipDetail?.id
              ? 'selected'
              : 'choose plan'}
          </div>
        </div>
      )}
    </div>
  );
};

export default CardVoucher;
