import { AuthContext } from '@/api/auth';
import { getMember, getMemberContractByMemberId } from '@/api/mutation';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { IMenu, MENU_ID, menus } from './data';
import ModalBase from '@/common/modals/ModalBase';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import { toastSuccess } from '@/common/toasts/toast';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import moment from 'moment';
import { useMemberShipContext } from '@/context/MembershipContext';

const Header = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [activeMenu, setActiveMenu] = React.useState<string>(MENU_ID.DASHBOARD);

  const [isLogOut, setIsLogOut] = React.useState<boolean>(false);

  const [userInfo, setUserInfo] = React.useState<any>(null);
  const [userInfoMemberShipV1, setUserInfoMemberShipV1] = React.useState<
    Array<any>
  >([]);
  const { userInfoMemberShip } = React.useContext(AuthContext);
  const [outStandingModalPay, setOutStandingModalPay] =
    React.useState<any>(false);

  const [getMembership] = useLazyQuery(getMemberContractByMemberId);

  const mountedFunction = () => {
    try {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });

          getMembership({
            variables: {
              memberId: user.attributes['custom:memberId'],
            },
          })
            .then((membership) => {
              setUserInfoMemberShipV1(
                membership.data.getMemberContractByMemberId.items.filter(
                  (i: any) => i.isActive
                )
              );
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((e) => {});
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  React.useEffect(() => {
    mountedFunction();
  }, []);
  const client = useApolloClient();
  const Init = React.useContext(AuthContext).Init;

  const refresh = React.useContext(AuthContext).refreshData;
  const setRefresh = React.useContext(AuthContext).setRefreshData;
  const {
    membershipInfo,
    getUserMembershipInfo,
    setUserSelectedMembership,
    userSelectedMembership,
  } = useMemberShipContext();
  React.useEffect(() => {
    if (refresh) {
      client
        .refetchQueries({
          include: ['getMember'], // Consider using "active" instead!
        })
        .then(() => {
          mountedFunction();
          setRefresh(false);
        });
    }
  }, [refresh]);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onChangeMenu = (menu: IMenu) => {
    if (menu?.isNotReady) {
      return;
    }
    if (
      menu.id.toLowerCase() === MENU_ID.CLASS &&
      userInfoMemberShipV1?.length > 0
    ) {
      navigate(menu.link);
    } else {
      navigate(menu.link);
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const arraySlugs = window.location.pathname.split('/').slice(2);
      setActiveMenu(arraySlugs?.[0] || MENU_ID.DASHBOARD);
    }
  }, [typeof window]);
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const membershipStatus = React.useMemo(() => {
    const result: Record<string, any> = {
      status: null,
      color: '',
      title: '',
    };
    return result;
  }, [userInfoMemberShip]);
  const [allMembershipStatus, setAllMembershipStatus] = useState({
    active: 0,
    suspended: 0,
    pendingSuspension: 0,
    cancelled: 0,
    pendingCancellation: 0,
    future: 0,
    remainingDay: 0,
  });
  useEffect(() => {
    const result = membershipInfo.reduce(
      (total: Record<string, number>, current: IContractWithStatus) => {
        if (current.status === 'active')
          return { ...total, active: total.active + 1 };
        if (current.status === 'suspended')
          return { ...total, suspended: total.suspended + 1 };
        if (current.status === 'pendingSuspension')
          return { ...total, pendingSuspension: total.pendingSuspension + 1 };
        if (current.status === 'pendingCancellation')
          return {
            ...total,
            pendingCancellation: total.pendingCancellation + 1,
          };
        if (current.status === 'future')
          return { ...total, future: total.future + 1 };
        if (current.status === 'cancelled')
          return { ...total, cancelled: total.cancelled + 1 };
      },
      {
        active: 0,
        suspended: 0,
        pendingSuspension: 0,
        cancelled: 0,
        pendingCancellation: 0,
        future: 0,
        remainingDay: 0,
      }
    );
    setAllMembershipStatus(result);
  }, [membershipInfo]);

  const membershipSummary = () => {
    return (
      <>
        {/* membership active */}
        {allMembershipStatus.active > 0 && (
          <div
            className={`flex items-center justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-primary`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.active} membership active
          </div>
        )}
        {/* membership pending suspension */}
        {allMembershipStatus.pendingSuspension > 0 && (
          <div
            className={`flex items-center justify-center w-max px-3 my-2 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-[#FF6900]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.pendingSuspension} pending suspension
          </div>
        )}
        {/* membership suspended */}
        {allMembershipStatus.suspended > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-[#FF6900]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.suspended} suspended
          </div>
        )}
        {/* membership pending cancelation */}
        {allMembershipStatus.pendingCancellation > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-red-500`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.pendingCancellation} pending cancelation
          </div>
        )}
        {/* membership cancelled */}
        {allMembershipStatus.future > 0 && (
          <div
            className={`flex items-center justify-center w-max my-2 px-3 py-1 rounded-[2px] h-[18px] text-black text-[10px] text-center font-bold uppercase bg-[#fbff4b]`}
          >
            {/* {membershipStatus.title} */}
            {allMembershipStatus.future} future
          </div>
        )}
      </>
    );
  };

  return (
    <header className="hidden sm:block">
      <div className="pt-[42px] pb-5">
        <div className="mb-9 px-6">
          <p className="text-white text-base font-bold leading-[160%] uppercase mb-[10px]">
            MEMBER PORTAL
          </p>
          <div
            className="h-[26px] w-[269px] h-[26px] cursor-pointer"
            onClick={() => openInNewTab('https://www.plusfitness.com.au/')}
          >
            <img
              className="sm:block hidden  mx-auto w-full h-full object-contain"
              src="/icons/pf-logo-line.svg"
            />
          </div>
        </div>

        <div className="flex items-start justify-between gap-3 mb-5 px-6">
          <div className="flex items-center gap-x-[14px]">
            <img
              className="rounded-full cursor-pointer border border-white max-w-[61px] max-h-[61px] w-[61px] h-[61px]"
              src={userInfo?.imageUrl || ''}
              alt="user avatar"
              onClick={() => navigate('/dashboard/account')}
            />
            <div>
              {' '}
              <p className="font-bicyclette font-bold text-primary text-3xl leading-[30px] uppercase">
                {userInfo?.givenName && userInfo?.surname
                  ? `${userInfo.givenName} ${userInfo.surname}`
                  : 'name'}
              </p>
              <>
                {' '}
                {userInfo?.isBlocked ? (
                  <div
                    className={`flex uppercase items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-textError`}
                  >
                    YOUR MEMBER CARD IS BEING BLOCKED
                  </div>
                ) : (
                  <div
                    className={`flex mt-1 items-center justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase ${membershipStatus.color}`}
                  >
                    {membershipStatus.title}
                  </div>
                )}
                {!userInfo?.isBlocked && membershipSummary()}
                {userInfo?.outstandingBalance &&
                  userInfo?.outstandingBalance !== 0 && (
                    <div
                      onClick={() => {
                        setOutStandingModalPay(true);
                      }}
                      className={`flex cursor-pointer uppercase mt-2 items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold  bg-textError`}
                    >
                      outstanding:${userInfo?.outstandingBalance}
                    </div>
                  )}
                {membershipStatus?.remainingDay && (
                  <p className="text-textSecondary text-left text-[10px] font-bold uppercase mt-2">
                    {membershipStatus?.remainingDay} DAYS REMAINING
                  </p>
                )}
              </>
            </div>
          </div>

          <div className="relative mt-[6px] cursor-pointer">
            <svg
              width="23"
              height="26"
              viewBox="0 0 23 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7376 20.1096C21.9933 19.4461 21.3417 18.6854 20.8003 17.8482C20.2093 16.6924 19.8551 15.4302 19.7584 14.1357V10.323C19.7635 8.28973 19.026 6.3246 17.6843 4.79679C16.3427 3.26899 14.4893 2.28368 12.4725 2.02601V1.03037C12.4725 0.757098 12.3639 0.49502 12.1707 0.301788C11.9775 0.108557 11.7154 0 11.4421 0C11.1688 0 10.9068 0.108557 10.7135 0.301788C10.5203 0.49502 10.4117 0.757098 10.4117 1.03037V2.04144C8.41295 2.3177 6.58199 3.30896 5.25799 4.83163C3.93399 6.3543 3.20667 8.3052 3.21074 10.323V14.1357C3.11407 15.4302 2.75983 16.6924 2.16879 17.8482C1.63692 18.6835 0.995788 19.4441 0.262416 20.1096C0.180088 20.1819 0.114106 20.2709 0.0688591 20.3707C0.0236124 20.4705 0.000138769 20.5788 0 20.6884V21.7381C0 21.9428 0.0813157 22.1391 0.226058 22.2838C0.370801 22.4286 0.567115 22.5099 0.771812 22.5099H22.2282C22.4329 22.5099 22.6292 22.4286 22.7739 22.2838C22.9187 22.1391 23 21.9428 23 21.7381V20.6884C22.9999 20.5788 22.9764 20.4705 22.9311 20.3707C22.8859 20.2709 22.8199 20.1819 22.7376 20.1096ZM1.60537 20.9663C2.32347 20.2726 2.95573 19.4952 3.48859 18.6508C4.23309 17.255 4.66748 15.7149 4.76208 14.1357V10.323C4.73147 9.41845 4.8832 8.517 5.20822 7.67233C5.53325 6.82765 6.02493 6.05701 6.65398 5.40631C7.28304 4.75561 8.0366 4.23815 8.8698 3.88474C9.70301 3.53133 10.5988 3.34921 11.5039 3.34921C12.4089 3.34921 13.3047 3.53133 14.1379 3.88474C14.9711 4.23815 15.7247 4.75561 16.3537 5.40631C16.9828 6.05701 17.4745 6.82765 17.7995 7.67233C18.1245 8.517 18.2762 9.41845 18.2456 10.323V14.1357C18.3402 15.7149 18.7746 17.255 19.5191 18.6508C20.052 19.4952 20.6842 20.2726 21.4023 20.9663H1.60537Z"
                fill="white"
              />
              <path
                d="M11.5392 25.0877C12.0254 25.0765 12.4919 24.8938 12.8563 24.5717C13.2207 24.2497 13.4595 23.8091 13.5304 23.328H9.4707C9.54362 23.8222 9.79357 24.2731 10.1741 24.5968C10.5545 24.9205 11.0397 25.095 11.5392 25.0877Z"
                fill="white"
              />
            </svg>
            <div className="w-[11px] h-[11px] bg-[#FF0000] rounded-full absolute top-0"></div>
          </div>
        </div>

        <div
          onClick={toggleOpen}
          className="relative rounded-md h-[54px] bg-richBlack flex items-center justify-between pl-5 pr-2 mx-[14px] cursor-pointer"
        >
          <p className="text-white text-base leading-[47.5px] font-bold uppercase">
            {menus.map((menu) => {
              if (menu.id !== activeMenu) return null;
              return menu.name;
            })}
          </p>
          <div className="h-5 cursor-pointer">
            <img
              className="block w-full object-contain"
              src={`/icons/${isOpen ? 'close-' : ''}menu-primary.png`}
              alt="menu"
            />
          </div>

          {isOpen && (
            <div className="absolute left-0 right-0 top-[58px] w-full rounded bg-richBlack z-[1000]">
              {/* MAIN TABS */}
              <div className="py-1">
                {menus.map((menuItem: IMenu) => {
                  const isFutureFeature =
                    menuItem.id === 'benefits' || menuItem.id === 'club-search';
                  const isDisabledFeature =
                    membershipInfo.length === 0 &&
                    (menuItem.id === 'class' || menuItem.id === 'memberships');
                  const isFeatureDisabled =
                    isDisabledFeature || isFutureFeature;
                  return (
                    <div
                      key={menuItem.id}
                      onClick={(
                        event: React.MouseEvent<HTMLDivElement, MouseEvent>
                      ) => {
                        if (isFeatureDisabled) {
                          return;
                        }
                        event.stopPropagation();
                        if (
                          userInfo?.outstandingBalance &&
                          menuItem.id !== 'class'
                        ) {
                          onChangeMenu(menuItem);
                        } else if (!userInfo?.outstandingBalance) {
                          onChangeMenu(menuItem);
                        }
                      }}
                      className={`cursor-pointer h-11 text-base text-white leading-[47.5px] font-bold uppercase px-5 ${
                        activeMenu === menuItem.id
                          ? ' !text-primary bg-white border-r-8 border-primary'
                          : ''
                      }  ${
                        userInfo?.outstandingBalance && menuItem.id === 'class'
                          ? '!text-[#848484] '
                          : ''
                      } ${isFeatureDisabled ? '!text-[#848484]' : ''}`}
                    >
                      {menuItem.name}
                    </div>
                  );
                })}
              </div>
              {/* SUB TABS */}
              <div className="">
                <div
                  onClick={() => setIsLogOut(true)}
                  className="cursor-pointer  rounded-b-md flex items-center overflow-hidden border-b border-black bg-primary h-11 text-base text-white leading-[47.5px] font-bold uppercase px-5"
                >
                  <svg
                    className="w-[19.7px] h-[18px] mr-[9.9px]"
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.1429 5.71429V2.5C12.1429 1.67157 11.4713 1 10.6429 1H2.5C1.67157 1 1 1.67157 1 2.5V17.5C1 18.3284 1.67157 19 2.5 19H10.6429C11.4713 19 12.1429 18.3284 12.1429 17.5V13.4286"
                      stroke="white"
                    />
                    <path
                      d="M16.5645 5.51074L20.7148 9.66112L16.5645 13.8115"
                      stroke="white"
                    />
                    <path d="M20.7143 9.57129L7 9.57129" stroke="white" />
                  </svg>
                  LOG OUT
                </div>
                {/*<div className="cursor-pointer rounded-b-md flex items-center overflow-hidden bg-primary h-11 text-base text-white leading-[47.5px] font-bold uppercase px-5">*/}
                {/*  <svg*/}
                {/*    className="w-[17.3px] h-[17.3px] mr-[13.28px]"*/}
                {/*    width="19"*/}
                {/*    height="19"*/}
                {/*    viewBox="0 0 19 19"*/}
                {/*    fill="none"*/}
                {/*    xmlns="http://www.w3.org/2000/svg"*/}
                {/*  >*/}
                {/*    <circle*/}
                {/*      cx="9.34772"*/}
                {/*      cy="9.34772"*/}
                {/*      r="8.65241"*/}
                {/*      stroke="white"*/}
                {/*    />*/}
                {/*    <path*/}
                {/*      d="M6.95703 7.55272C6.95703 7.55272 6.99057 6.75337 7.73825 6.11244C8.1822 5.7319 8.71535 5.62169 9.19307 5.61491C9.62933 5.60934 10.0192 5.68612 10.2524 5.80433C10.6509 6.00731 11.4291 6.50146 11.4291 7.55272C11.4291 8.65896 10.7495 9.16037 9.97685 9.71289C9.20425 10.2654 9.00674 10.8073 9.00674 11.4283"*/}
                {/*      stroke="white"*/}
                {/*      strokeMiterlimit="10"*/}
                {/*      strokeLinecap="round"*/}
                {/*    />*/}
                {/*    <path*/}
                {/*      d="M9.19135 13.2172C9.43834 13.2172 9.63856 13.0169 9.63856 12.77C9.63856 12.523 9.43834 12.3228 9.19135 12.3228C8.94436 12.3228 8.74414 12.523 8.74414 12.77C8.74414 13.0169 8.94436 13.2172 9.19135 13.2172Z"*/}
                {/*      fill="white"*/}
                {/*    />*/}
                {/*  </svg>*/}
                {/*  HELP*/}
                {/*</div>*/}
              </div>
            </div>
          )}
        </div>
      </div>

      {isLogOut && (
        <ModalBase closeModal={setIsLogOut}>
          <div className="md:w-auto w-[700px] h-[400px] flex flex-col items-center justify-center">
            <h2 className="text-[40px] font-bold text-center sm:px-6 sm:text-2xl px-24 leading-[90%] text-white uppercase font-bicyclette">
              are you sure you want to log out?
            </h2>
            <div className="flex items-center mt-[30px] justify-center gap-[20px] ">
              <PrimaryOutlinedButton
                onClick={() => {
                  window.localStorage.removeItem('userInfo');
                  window.localStorage.removeItem('login');
                  Auth.signOut().then((res) => {
                    setUserSelectedMembership(null);
                    Init();
                    navigate('/login');
                  });
                }}
                className="rounded-md w-[108px] !px-0 uppercase"
              >
                log out
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                onClick={() => setIsLogOut(false)}
                className="rounded-md w-[108px] !px-0 uppercase"
              >
                cancel
              </PrimaryOutlinedButton>
            </div>
          </div>
        </ModalBase>
      )}
      {outStandingModalPay && (
        <ModalMakePayment
          changeMembershipData={{
            newMemberShipId: '',
            changeMembershipType: '',
            locationId: userInfoMemberShip[0]?.locationId
              ? userInfoMemberShip[0]?.locationId
              : userInfo?.homeLocationId,
          }}
          setModalMakePayment={setOutStandingModalPay}
          amount={userInfo?.outstandingBalance}
          paymentType="outstanding"
          openModalOutside={setOutStandingModalPay}
          setStep={() => {
            toastSuccess('Thank you for paying outstanding balance');
            setUserInfo({ ...userInfo, outstandingBalance: 0 });
          }}
        />
      )}
    </header>
  );
};

export default Header;
